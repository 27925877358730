<template>
    <div class="PeriodSettlement" v-if="period">
        <StudentTopNavBar :fund="fund"></StudentTopNavBar>
        <Toolbar :fund="fund" :period="period" @next="nextPeriod"></Toolbar>
        <div class="main">
            <div class="profit">
                {{ stepList[period - 1] }}利润: {{ profit }}
            </div>
            <div class="goods-sales-data">
                商品销售情况({{ stepList[period - 1] }})
            </div>
            <div class="table-data">
                <el-table :data="goodsList" border style="width: 100%" class="custom-table">
                    <el-table-column prop="$index" label="序号" width="60" align="center">
                        <template slot-scope="scope">
                            {{scope.$index + 1}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="商品信息">
                        <template slot-scope="scope">
                            <div class="goods">
                                <img v-if="scope.row.image" class="goods_img" :src="scope.row.image" :alt="scope.row.old_name" :title="scope.row.old_name">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="old_name" label="商品初始名"></el-table-column>
                    <el-table-column prop="marketvalue" label="市场价"> </el-table-column>
                    <el-table-column prop="number_all" label="总库存"> </el-table-column>
                    <el-table-column prop="click" label="点击率"> </el-table-column>
                    <el-table-column prop="conversion" label="转化率"> </el-table-column>
                    <el-table-column prop="total_cost" label="成本总价"> </el-table-column>
                    <el-table-column prop="sales_volume" label="销售量"> </el-table-column>
                    <el-table-column prop="sales_count" label="销售额"> </el-table-column>
                </el-table>
            </div>
            <div class="paging">
                <el-pagination
                    @current-change="getGoodsList"
                    :current-page="params.page"
                    :page-size="params.pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="params.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import StudentTopNavBar from "components/student/periodSettlement/StudentTopNavbar.vue"
import Toolbar from "components/student/periodSettlement/Toolbar.vue"
export default {
    name: 'PeriodSettlement',
    components: {
        StudentTopNavBar,
        Toolbar
    },
    props: {},
    data() {
        return {
            stepList: ["第一周期", "第二周期", "第三周期"],
            period: void 0,
            goodsList: [],
            profit: 0,
            fund: 0,
            params: {
                paging: 1,
                page: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    computed: {},
    methods: {
        validateRes(res) {
            const valid = res.code == 200;
            valid || this.$message.error(res.msg);
            return valid;
        },
        getGoodsList() {
            this.$http.axiosGetBy(this.$api.storeSelectionAddGoods, this.params, res => {
                if(!this.validateRes(res)) return;
                this.period = res.data.cycle;
                this.goodsList = res.data.data;
                this.params.total = res.data.total;
                this.fund = res.data.fund;
                this.profit = res.data.totalProfit;
            }, err => {
                console.log(err);
            })
        },
        nextPeriod() {
            const competition_id = localStorage.getItem("com_id");
            // if(!competition_id) {
            //     this.$message.error("未找到竞赛/训练id");
            //     return;
            // }
            this.$http.axiosPost(this.$api.studentStoreSelection, { competition_id }, res => {
                if(!this.validateRes(res)) return;
                this.$router.push("/student/operationpromotion/index");
            }, err => {
                console.log(err);
            })
        }
    },
    created() {
        this.getGoodsList();
    }
}
</script>

<style scoped lang="scss">
.PeriodSettlement {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    .main {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 30px;
        .goods-sales-data {
            font-size: 16px;
            margin: 20px 0;
            font-weight: 700;
        }
        .table-data {
            /* flex: 1; */
            .goods {
                display: flex;
                align-items: center;
                .goods_img {
                    width: 50px;
                    height: 50px;
                    margin-right: 5px;
                }
            }
        }
        .paging {
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
