<template>
    <div class="toolbar flex justify-between">
        <!-- <div class="data-summary">
            <el-button type="primary" class="content-center" @click="toDataSummary">
                <i class="iconfont">&#xe6d5;</i>
                数据汇总
            </el-button>
        </div> -->
        <div class="chunk flex justify-evenly" style="flex: 1;">
            <!-- <div class="item download-material content-center">
                <el-button class="custom-button content-center" @click="downloadMaterial">
                    <i class="iconfont">&#xe856;</i>
                    下载素材
                </el-button>
            </div> -->
            <div class="current-funds content-center">
                <div class="containers content-center">
                    <!-- <i class="iconfont">&#xe6e4;</i> -->
                    <span>采购资金：</span> 
                    <span class="finds">
                        <span class="decorate">￥</span>
                        {{ fund }}
                    </span>
                </div>
            </div>
            <div class="current-progress content-center">
                <div class="containers content-center">
                    <!-- <i class="iconfont">&#xe643;</i> -->
                    <span>当前进程：</span>
                    <span>{{ stepList[period - 1] }}准备期 <span class="ready-period">第{{ period }}周期</span></span>
                </div>
            </div>
        </div>
        <div class="chunk flex justify-around items-center"  style="flex: 1.5;">
            <div class="progress flex content-center">
                <div class="progress-item" v-for="(item, index) in stepList" :class="getClass(item, index)">
                    <div class="bg-box flex justify-center items-center">{{item}}</div>
                </div>
            </div>
            <div class="calculation-btn">
                <el-button v-if="period < stepList.length" class="start-new-quarter" type="warning" @click="startNewQuarter">开始新阶段设置</el-button>
                <!-- <el-button v-else-if="period < 4" class="next-submit"  type="warning" @click="toCalculationRes">确认提交并进入下一个季度</el-button> -->
                <el-button v-else  class="start-new-quarter" type="danger" @click="finishExam">结束考试</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    name: 'Toolbar',
    components: {},
    props: {
        period: {
            type: Number,
            default: 1
        },
        fund: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
            stepList: ["第一周期", "第二周期", "第三周期"],
            type: localStorage.getItem("type"),
            balance: 1
        }
    },
    methods: {
        getClass(item, index) {
            let baseClass = this.period > index ? ['active'] : [];
            if(index == 0) {
                baseClass.push("first-item")
            }else if(index == this.stepList.length - 1) {
                baseClass.push("last-item");
            }
            return baseClass;
        },
        startNewQuarter() {
            this.$emit("next")
        },
        finishExam() {
            const path = this.type == 1 ? "/student/settlementExam" : "/student/settlementTrainData";
            this.$router.push(path);
        }
    }
}
</script>

<style scoped lang="scss">
$theme: #13ABF7;
.flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.justify-evenly {
    justify-content: space-evenly;
}
.justify-around {
    justify-content: space-around;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.iconfont {
    color: #666;
}
.content-center {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.toolbar {
    position: relative;
    box-sizing: border-box;
    height: 71px;
    width: 100%;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1500);
    z-index: 9;
    .data-summary {
        /* flex: 20; */
        .content-center {
            margin-top: 15px;
            margin-left: 10px;
            background-color: $theme;
            .iconfont {
                margin-right: 5px;
                color: #fff;
            }
        }
    }
    .download-material {
        /* flex: 16; */
        .custom-button {
            background-color: #fff;
            color: #666666;
            border: none;
            
            .iconfont {
                margin-right: 7px;
                font-size: 16px;
            }
            &:hover {
                color: $theme;
                .iconfont {
                    color: $theme;
                }
            }
        }
    }
    .current-funds {
        /* flex: 23; */
        .iconfont {
            margin-right: 3px;
            font-size: 25px;
        }
        .finds {
            color: #FF2424;
            font-size: 18px;
            .decorate {
                margin-right: -5px;
                font-size: 14px;
            }
        }
    }
    .current-progress {
        /* flex: 43; */
        .iconfont {
            margin-right: 7px;
            font-size: 16px;
        }
        .ready-period {
            font-size: 15px;
            font-weight: 700;
        }
    }
    .progress {
        /* flex: 66; */
        .progress-item {
            position: relative;
            width: 140px;
            height: 30px;
            margin-right: 5px;
            border: 1px solid #C9C9C9;
            .bg-box {
                position: absolute;
                width: 123px;
                height: 100%;
                top: 0;
                left: 15px;
            }
            &::after,
            &::before {
                content: "";
                position: absolute;
                width: calc(30px * 1.414 / 2);
                height: calc(30px * 1.414 / 2);
                top: 50%;
                right: -11.5px;
                bottom: 0;
                transform: translateY(-50%) rotate(45deg);
                border: 1px solid #C9C9C9;
                border-left: none;
                border-bottom: none;
                background-color: #fff;
                z-index: 99;
            }
            &::before {
                right: none;
                left: -11.5px;
                z-index: 9;
            }
        }
        .first-item {
            &::before {
                display: none;
            }
        }
        .last-item {
            &::after {
                display: none;
            }
        }
        .active {
            color: #fff;
            background-color: $theme;
            border: none;
            &::before {
                background-color: #fff;
                border: none;
            }
            &::after {
                background-color: $theme;
                border: none;
            }
        }
    }
}
:deep(.el-button--warning) {
    background-color: #FEA523;
}
</style>
